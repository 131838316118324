<template>
  <div class="pt-2 pr-4 pb-2 pl-4 purchase-ht1">
    <form @submit.prevent="submit" class="row purchase-ht4">
      <div class="col-md-12 pb-1">
        <div class="row">
          <div class="col-md-10 pl-0">
            <h4 class="content-title content-title-xs">New Estimate</h4>
          </div>
          <div class="col-md-2 float-right text-right pr-0 pl-5">
            <select v-model="formData.branch" class="form-control right-select-drop">
              <option value>Select Branch</option>
              <option
                v-for="(branch, index) in dataLists6.branches"
                :key="index"
                :value="branch.id"
              >{{ branch.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-3 bg-theam-secondary mx-0 p-2">
        <div class="purchase-supplier-ht">
          <div>
            <span class="title-supplier">Lead</span>
            <button
              class="float-right btn btn-primary btn-xs"
              @click="$store.dispatch('modalOpen', 'newLead')"
            >New Lead</button>
          </div>
          <div class="input-group py-2">
            <button class="btn-primary input-group-text" id="lead_name">
              <i class="fa fa-user"></i>
            </button>
            <!-- <input
              type="text"
              class="form-control"
              placeholder="Lead Name"
              name="supplier_name"
              data-toggle="modal"
              data-target="#customerList"
            />-->
            <input
              type="text"
              class="form-control"
              placeholder="Lead Name"
              v-model="filterData.lead_name"
              data-toggle="modal"
              data-target="#leadList"
            />
          </div>
          <div class="row">
            <div class="col-md-5 mr-0 pr-0">
              <label for="date" class="page-text">Estimate Date *</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                type="date"
                class="form-control"
                id="purchase_date"
                name="purchase_date"
                v-model="formData.estimate_date"
              />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-md-5 mr-0 pr-0">
              <label for="purchase_id" class="page-text">Estiamate ID</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                type="text"
                class="form-control"
                id="purchase_id"
                name="purchase_id"
                placeholder="EST005"
                disabled
                v-model="formData.id"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 mr-0 pr-0">
              <label for="date" class="page-text">Validity</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                type="date"
                class="form-control"
                id="purchase_date"
                name="purchase_date"
                v-model="formData.validity"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 mx-0 pl-2 pr-0 purchase-ht2">
        <div class="bg-theam-secondary p-2">
          <div class="row pt-0">
            <div class="col-md-4 mr-0 pr-0">
              <div class="page-text">Item</div>
              <div class="input-group zi-2000">
                <div class="input-group-prepend">
                  <button type="button" class="btn-primary input-group-text" id="product">
                    <i class="fas fa-briefcase"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="filterData.item_name"
                  placeholder="Items"
                  data-toggle="modal"
                  data-target="#itemList"
                />
              </div>
            </div>

            <div class="col-md-2 mr-0 pr-0 zi-0">
              <div class="page-text">Stock *</div>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  placeholder="0"
                  name="quantity"
                  v-model="items.available"
                  disabled
                />
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    id="quantity"
                    style="background: #8db8ee; color: black"
                  >{{items.unit_type}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-2 pr-0">
              <div class="page-text">Expiry Date</div>
              <div class="input-group">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Supplier Name"
                  name="expiry_date"
                  v-model="items.expiry_date"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 pt-2 pr-0">
              <div class="page-text">Description</div>
              <textarea
                name="item_description"
                class="form-control ht-49"
                v-model="items.item.description"
              ></textarea>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-3 mr-0 pr-0 pt-2 zi-0">
                  <div class="page-text">Quantity *</div>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0"
                      name="quantity"
                      v-model="items.quantity"
                    />
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        id="quantity"
                        style="background: #8db8ee; color: black"
                      >{{items.unit_type}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 mr-0 pr-0 pt-2">
                  <div class="page-text">Sales Rate *</div>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      name="rate"
                      placeholder="Amount"
                      v-model="items.sales_rate"
                    />
                  </div>
                </div>
                <div class="col-md-4 pt-2">
                  <div class="page-text">Sales Discount</div>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0.00"
                      name="sales_discount"
                      v-model="items.sales_discount_percentage"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="min-width: 0px">%</span>
                    </div>
                    <input
                      type="number"
                      class="form-control wd-20pi"
                      placeholder="0.00"
                      name="sales_discount"
                      v-model="items.sales_discount_amount"
                    />
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-1 mr-0 pr-0">
                  <label for="date" class="page-text">Total</label>
                </div>
                <div class="col-md-3 ml-0 pl-0">
                  <input
                    type="number"
                    class="form-control"
                    id="purchase_date"
                    name="purchase_date"
                    disabled
                    v-model="items.total"
                  />
                </div>
                <div class="col-md-9 d-flex justify-content-end">
                  <div v-if="options.edit">
                    <div
                      class="btn btn-success btn-xs"
                      @click="updateItem"
                      type="button"
                    >Update Item</div>
                    <div
                      class="btn btn-danger btn-xs ml-2"
                      @click="options.edit = false"
                      type="button"
                    >Close</div>
                  </div>
                  <div v-else class="btn btn-success btn-xs" @click="addItem" type="button">Add Item</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-theam-secondary mt-2 purchase-ht3 purchase-table">
          <table class="table table1">
            <thead>
              <tr>
                <th scope class="wd-5p table-start-item">S.N</th>
                <th scope class="wd-50p">Particular</th>
                <th scope class="wd-15p">Qty</th>
                <th scope class="wd-10p">Rate</th>
                <th scope class="wd-10p">Total</th>
                <th scope class="wd-10p text-right table-end-item">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.items" :key="index">
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }} KG</td>
                <td>{{ item.sales_rate }} KG</td>
                <td>{{ item.total }}</td>
                <td class="text-right table-end-item">
                  <a href="javascript:;" class="mr-2" @click="editItem(index)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a href="javascript:;" class="mr-0" @click="removeItem(index)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 bg-theam-secondary p-2 mt-2">
        <div class="row">
          <div class="col-md-3 pr-0">
            <div class="page-text">Note</div>
            <textarea name="note" class="form-control page-note" v-model="formData.note"></textarea>
          </div>
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-3 mr-0 pr-0">
                <div class="page-text">Grand Total</div>
                <div class="input-group">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model="formData.total"
                    placeholder="0.00"
                    disabled
                  />
                </div>
              </div>
              <div class="col-md-3 mr-0 pr-0" v-if="authData.general_setting.vatable==1">
                <div class="page-text">VAT-able Amount</div>
                <div class="input-group">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    v-model="formData.vatable_amount"
                    placeholder="0.00"
                  />
                </div>
              </div>
              <div class="col-md-3 mr-0 pr-0" v-if="authData.general_setting.vatable==1">
                <div class="page-text">VAT Amount</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="0.00"
                    v-model="formData.vat_amount"
                    disabled
                  />
                </div>
              </div>
              <div class="col-md-3 mr-0 pr-0" v-if="authData.general_setting.vatable==1">
                <div class="page-text">Estimate Total</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.estimate_total"
                    placeholder="0.00"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 text-right">
            <button type="submit" class="btn btn-success btn-sm btn-min-wd">Submit</button>
            <button type="button" class="btn btn-warning btn-sm btn-min-wd my-2">Reset Item</button>
            <button type="button" class="btn btn-danger btn-sm btn-min-wd">Cancel</button>
          </div>
        </div>
      </div>
    </form>
    <!--    modals-->
    <div class="row">
      <!--      lead lists-->
      <div class="col-md-12">
        <div class="modal fade" id="leadList" role="dialog" aria-hidden="true">
          <div class="modal-dialog listing-popup-place modal_ac_head" role="document">
            <div class="modal-content search-table-container">
              <table class="table table1">
                <thead>
                  <tr>
                    <th scope class="wd-5p table-start-item">S.N</th>
                    <th scope class="wd-15p">Lead Id</th>
                    <th scope class="wd-30p">Lead Name</th>
                    <th scope class="wd-30p">Address</th>
                    <th scope class="wd-20p">Phone No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lead, index) in dataSets.leads"
                    :key="index"
                    @click="selectLead(lead.id)"
                  >
                    <th scope="row" class="table-start-item">{{ ++index }}</th>
                    <td>SPLR{{ threeDigit(lead.id) }}</td>
                    <td>{{ lead.name }}</td>
                    <td>{{ lead.address }}</td>
                    <td>{{ lead.phone_number }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--      items list-->
      <div class="col-md-12">
        <div class="modal fade" id="itemList" role="dialog" aria-hidden="true">
          <div class="modal-dialog listing-popup-place modal_ac_head" role="document">
            <div class="modal-content search-table-container">
              <table class="table table1">
                <thead>
                  <tr>
                    <th scope class="wd-5p table-start-item">S.N</th>
                    <th scope class="wd-15p">Purchase Id</th>
                    <th scope class="wd-35p">Item Name</th>
                    <th scope class="wd-15p">Group</th>
                    <th scope class="wd-10p">Unit Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in dataSets.items"
                    :key="index"
                    @click="selectItem(item.id)"
                  >
                    <th scope="row" class="table-start-item">{{ ++index }}</th>
                    <td>PRCH{{ threeDigit(item.purchase_id) }}</td>
                    <td>{{ item.item.name }}</td>
                    <td>{{ item.item.item_group.title }}</td>
                    <td>{{ item.item.unit_type.title }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewLead />
    <NewItem />
  </div>
</template>
<script>
import NewLead from "./NewLead";
import NewItem from "../Item/NewItem";
import { mapGetters } from "vuex";
export default {
  components: {
    NewLead,
    NewItem,
  },
  data() {
    return {
      options: {
        edit: false,
        index: null,
      },
      filterData: {
        lead_name: null,
        item_name: null,
      },
      dataSets: {
        leads: [],
        items: [],
      },
      formData: {
        id: "",
        branch: "",
        lead_id: "",
        estimate_date: null,
        validity: "",
        vatable_amount: null,
        vat_amount: null,
        estimate_total: null,
        total: null,
        note: null,
        items: JSON.parse(localStorage.getItem("items"))
          ? JSON.parse(localStorage.getItem("items"))
          : [],
      },
      items: {
        item_id: null,
        vat_applicable: null,
        item_name: null,
        available: null,
        quantity: 0,
        unit_type: null,
        sales_rate: 0,
        sales_discount_percentage: 0,
        sales_discount_amount: 0,
        total: 0,
        item: {
          description: null,
        },
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "today",
      "dataId",
      "dataLists6",
      "dataLists8",
      "removeList",
      "pagination",
      "eventMessage",
      "modalId",
      "today",
      "authData",
    ]),
  },
  mounted() {
    this.$store.commit("getData6", `api/lead-estimate/create`);
    this.setData();
  },
  methods: {
    setData() {
      this.formData.estimate_date = this.today;
    },
    selectLead(id) {
      let details = this.dataSets.leads.find(function (data) {
        return data.id == id;
      });
      this.filterData.lead_name = details.name;
      this.formData.lead_id = details.id;
    },
    selectItem(id) {
      let details = this.dataSets.items.find(function (data) {
        return data.id == id;
      });
      this.items = details;
      this.filterData.item_name = details.item.name;
      this.items.item_id = details.item.id;
      this.items.name = details.item.name;
      this.items.vat_applicable = details.item.vat_applicable;
      this.items.unit_type = details.item.unit_type.title;
      this.items.quantity = 0;
    },

    calculateTotal() {
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.estimate_total = 0;

      this.formData.total = 0;
      this.formData.items.forEach((item) => {
        this.formData.total = this.formData.total + parseFloat(item.total);
        if (item.vat_applicable == 1) {
          this.formData.vatable_amount =
            parseFloat(this.formData.vatable_amount) + parseFloat(item.total);
        }
      });

      this.calculateCustomAmount();
    },
    calculateCustomAmount() {
      this.formData.vat_amount =
        parseFloat(this.formData.vatable_amount) * 0.13;
      this.formData.estimate_total =
        parseFloat(this.formData.total) + parseFloat(this.formData.vat_amount);
    },
    calculateItemTotal() {
      if (this.items.sales_discount_amount > 0) {
        this.items.total =
          this.items.quantity *
          (this.items.sales_rate - this.items.sales_discount_amount);
      } else {
        this.items.total = this.items.quantity * this.items.sales_rate;
      }
      this.items.total = this.items.total.toFixed(2);
    },
    addItem() {
      if (
        this.items.item_id &&
        this.items.quantity > 0 &&
        this.items.sales_rate > 0 &&
        this.items.total > 0
      ) {
        if (this.formData.items.length > 0) {
          let item_id = this.items.item_id;
          this.filterData.items = JSON.parse(
            localStorage.getItem("items")
          ).find(function (data) {
            return data.item_id == item_id;
          });
          if (this.filterData.items == undefined) {
            this.formData.items.push({ ...this.items });
            this.clearItem();
          } else {
            this.$store.dispatch("enableMessage");
            this.$store.commit(
              "setEventMessage",
              `${this.filterData.items.name} already in purchased list.`
            );
          }
        } else {
          this.formData.items.push({ ...this.items });
          this.clearItem();
        }
      } else {
        this.$store.dispatch("enableMessage");
        this.$store.commit(
          "setEventMessage",
          "Please fill the required fields."
        );
      }
    },
    removeItem(index) {
      if (confirm("Are you sure want to cancel ?")) {
        this.formData.items.splice(index - 1, 1);
      }
      return false;
    },
    editItem(index) {
      this.items = { ...this.formData.items[index - 1] };
      this.filterData.item_name = this.items.name;
      this.options.edit = true;
      this.options.index = [index - 1];
    },
    updateItem() {
      this.formData.items[this.options.index] = { ...this.items };
      this.calculateTotal();
    },
    submit() {
      console.log(this.formData);
      this.$store.commit("setApiUrl", "api/lead-estimate/store");
      this.$store.commit("addData", this.formData);
    },
    clearItem() {
      this.items.item_id = null;
      this.items.item_name = null;
      this.items.available = 0;
      this.items.quantity = 0;
      this.items.sales_rate = 0;
      this.items.sales_discount_percentage = 0;
      this.items.sales_discount_amount = 0;
      this.items.total = 0;
    },
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("Sales successfully") >= 0) {
        this.$router.push("/sales/manage");
      }
    },
    "formData.branch"(value) {
      if (value) {
        this.$store.commit("getData8", `api/sales/items/branch/${value}`);
      }
    },
    dataLists6(value) {
      if (value.length != 0) {
        this.dataSets.leads = value.leads;
      }
    },

    dataLists8(value) {
      if (value.length != 0) {
        this.dataSets.items = value;
      }
    },
    "items.quantity"(value) {
      if (value > this.items.available) {
        this.items.quantity = this.items.available;
      }
      this.calculateItemTotal();
    },
    "items.sales_rate"() {
      this.calculateItemTotal();
    },
    "items.sales_discount_amount"() {
      this.calculateItemTotal();
    },
    "formData.items"(value) {
      localStorage.setItem("items", JSON.stringify(value));
      this.calculateTotal();
    },
    "formData.vatable_amount"() {
      this.calculateCustomAmount();
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
    localStorage.removeItem("items");
  },
};
</script>
<style scoped>
.form-control-custom h6 {
  font-weight: 600;
}
.right-select-drop {
  background: #1b52bf !important;
  color: #f4f7fa !important;
  border-radius: 5px !important;
  height: 1.4rem !important;
  font-size: 0.75rem;
}
.input-group-text {
  background: #1b52bf;
  color: #f4f7fa;
  z-index: 20;
}
.input-group-text {
  outline: none;
}
input,
select,
textarea {
  background: #f4f7fa;
}
input:focus,
select:focus,
textarea:focus {
  background: #ffffff;
}
.form-control:disabled {
  background: #9ac3f0 !important;
  color: black !important;
}
</style>
